/* @import '~antd/dist/antd.css'; */

/* fonts */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url('./assets/fonts/Roboto/Roboto-Italic.ttf') format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: bold;
  src: url('./assets/fonts/Roboto/Roboto-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url('./assets/fonts/Roboto/Roboto-Black.ttf') format('ttf');
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto';
  /* font-weight: 400; */
}
/* end fonts */

body,
p {
  color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: white;
  font-weight: bold;
}

.form-label {
  color: white;
}

.form-input {
  background-color: transparent;
  color: white;
}

/* antd overrides */
.ant-input:focus {
  border-color: #d15531;
  outline: 0;
  /* -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2); */
  /* box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2); */
}

.ant-input:hover {
  border-color: #d15531;
  outline: 0;
  /* -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2); */
}

.ant-input::placeholder {
  color: grey;
}

/* end antd overrides */
